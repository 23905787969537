
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
export default defineComponent({
  setup() {
    const router = useRouter();
    const goRouteLink = (name: string) => {
      router.push({ name });
    };
    const store = useStore();
    store.dispatch("GetUserInfo");
    return {
      goRouteLink,
    };
  },
});
